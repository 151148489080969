<template>
    <div class="partnership-modal modal-bordered">
        <h1 class="container-title" v-html="$content('support_modal_title')"></h1>
        <AuthInput v-model="form.email" placeholder="support_modal_email_placeholder"></AuthInput>
        <textarea
            v-model="form.text"
            :placeholder="$content('support_modal_text_placeholder')"
            class="auth-input"
            cols="30"
            rows="10"></textarea>
        <div class="w-100">
            <ProjectButton variant="green" radius="10" @click.native="supportAppeal">
                <div class="w-100" v-html="$content('support_modal_btn')"></div>
            </ProjectButton>
        </div>
    </div>
</template>

<script>
import AuthInput from "@/components/Inputs/AuthInput";
import ProjectButton from "@/components/Buttons/ProjectButton";
export default {
    name: "Partnership",
    components: {ProjectButton, AuthInput},
    data() {
        return {
            form: {
                email: '',
                text: ''
            }
        }
    },
    methods: {
        supportAppeal() {
            this.$http('client').supportAppeal(this.form)
        }
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .partnership-modal {
        padding: 35px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 500px;

        @include only-xs {
            width: 100%;
        }

        & input {
            background: var(--main-input);
        }

        & h1 {
            margin-bottom: 33px;
        }

        & textarea {
            width: 100%;
            background: var(--main-input);
            height: 150px;
            padding-left: 22px;
            padding-top: 10px;
            color: var(--main-dark-paper);
            font-family: Montserrat-Medium, sans-serif;
            font-size: 15px;
        }
    }
</style>